import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('account', ['getSelectedAccount'])
  },
  created() {
    if (!this.getSelectedAccount) {
      this.goToWelcomeScreen();
    }
  }
};
